import { render, staticRenderFns } from "./ResourceFTContent.vue?vue&type=template&id=0fd63ade&scoped=true&"
import script from "./ResourceFTContent.vue?vue&type=script&lang=js&"
export * from "./ResourceFTContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd63ade",
  null
  
)

export default component.exports